import { createSlice } from "@reduxjs/toolkit";

import * as CLOUD_API from "@skydio/api_util/src/backends/cloud_api";

import type { PayloadAction } from "@reduxjs/toolkit";

export interface CurrentUserState {
  user?: CLOUD_API.APIUser;
  organization?: CLOUD_API.APIOrganization;
  requestedOrgUuid?: string;
}

const initialState: CurrentUserState = {};

const { actions, reducer } = createSlice({
  name: "currentUser",
  initialState,
  reducers: {
    updateRequestedOrganization(state, { payload }: PayloadAction<string>) {
      state.requestedOrgUuid = payload;
    },
  },
  extraReducers: builder =>
    builder
      .addCase(CLOUD_API.modules.auth.asyncThunks.checkAuth.fulfilled, (state, { payload }) => {
        state.user = payload;
      })
      .addCase(
        CLOUD_API.modules.organizations.asyncThunks.fetchOrganization.fulfilled,
        (state, { payload }) => {
          state.organization = payload;
        }
      )
      .addCase(
        CLOUD_API.modules.organizations.asyncThunks.updateOrganization.fulfilled,
        (state, { payload }) => {
          if (payload.uuid === state.organization?.uuid) {
            state.organization = payload;
          }
        }
      ),
});

export { actions as currentUserActions };

export default reducer;
