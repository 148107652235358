import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { DataFile, DeselectedFilesOfFlightMap, FileMap, Flight, FlightMap } from "./types";

export interface FileSelectorState {
  selectedFileMap: FileMap;
  selectedFlightMap: FlightMap;
  /*
    deselectedFilesOfFlightMap [key: flightID, value: list of Files] keeps track of files that 
    were directly deslected when its flight has first been selected.    
   */
  deselectedFilesOfFlightMap: DeselectedFilesOfFlightMap;
}

const initialState: FileSelectorState = {
  selectedFileMap: {},
  selectedFlightMap: {},
  deselectedFilesOfFlightMap: {},
};

const { reducer, actions } = createSlice({
  name: "fileSelector",
  initialState,
  reducers: {
    deselectAllFiles(state) {
      state.selectedFileMap = {};
      state.selectedFlightMap = {};
      // no need to track individual deslected files if all flights have been deselected
      state.deselectedFilesOfFlightMap = {};
    },
    selectFile(state, action: PayloadAction<DataFile>) {
      // file's flight is not selected, so track it directly
      if (!(action.payload.flightId in state.deselectedFilesOfFlightMap)) {
        state.selectedFileMap[action.payload.uuid] = action.payload;
        return;
      }
      // file was previously deselected while its flight was selected, so remove it from deselectedFilesOfFlightMapcl as it is now selected
      if (state.deselectedFilesOfFlightMap[action.payload.flightId].includes(action.payload.uuid)) {
        state.deselectedFilesOfFlightMap[action.payload.flightId] =
          state.deselectedFilesOfFlightMap[action.payload.flightId].filter(
            fileUuid => fileUuid !== action.payload.uuid
          );
      }
    },
    deselectFile(state, action: PayloadAction<DataFile>) {
      if (action.payload.uuid in state.selectedFileMap) {
        delete state.selectedFileMap[action.payload.uuid];
      }
      // only track deselected files if its flight has first been selected
      if (action.payload.flightId in state.selectedFlightMap) {
        state.deselectedFilesOfFlightMap[action.payload.flightId].push(action.payload.uuid);
      }
    },
    selectFlight(state, action: PayloadAction<Flight>) {
      state.selectedFlightMap[action.payload.uuid] = action.payload;
      // all files are considered selected if its flight is selected, so deselected files of a flight are cleared
      state.deselectedFilesOfFlightMap[action.payload.uuid] = [];
    },
    deselectFlight(state, action: PayloadAction<Flight>) {
      if (action.payload.uuid in state.selectedFlightMap) {
        delete state.selectedFlightMap[action.payload.uuid];
        // deslects all files of flight that were directly selected
        Object.values(state.selectedFileMap).forEach(file => {
          if (file.flightId === action.payload.uuid) delete state.selectedFileMap[file.uuid];
        });
        delete state.deselectedFilesOfFlightMap[action.payload.uuid];
      }
    },
  },
});

export default reducer;
export { actions as fileSelectorActions };
