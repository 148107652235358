import { createSlice } from "@reduxjs/toolkit";

import { pilotActions } from "state/pilot/slice";
import { teleopActions } from "state/teleop/slice";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { MissionState as MissionStatePB } from "@skydio/pbtypes/pbtypes/vehicle/skills/mission_pb";

export interface MissionState
  extends Pick<
    MissionStatePB.AsObject,
    | "stateEnum"
    | "missionName"
    | "actionState"
    | "waypointIndex"
    | "numWaypoints"
    | "remainingTimeEstimate"
    | "remainingDistanceEstimate"
    | "templateUuid"
    | "freeLookActive"
    | "supportedFreeLookMode"
  > {
  currentSkillKey?: string;
  freeLookInitialized: boolean;
}

const initialState = { freeLookActive: false, freeLookInitialized: false } as MissionState;

const { reducer, actions: missionActions } = createSlice({
  name: "mission",
  initialState,
  reducers: {
    handleMissionState(state, { payload }: PayloadAction<MissionStatePB.AsObject>) {
      state.stateEnum = payload.stateEnum;
      state.missionName = payload.missionName;
      state.actionState = payload.actionState;
      // Store incoming waypoint index only if it's not invalid, so that we can keep track of the mission progress even when the mission is stopped in the middle and the drone sends an invalid waypoint index
      state.waypointIndex =
        payload.waypointIndex === -1 && payload.templateUuid !== ""
          ? state.waypointIndex
          : payload.waypointIndex;
      state.numWaypoints = payload.numWaypoints;
      state.remainingTimeEstimate = payload.remainingTimeEstimate;
      state.remainingDistanceEstimate = payload.remainingDistanceEstimate;
      state.templateUuid = payload.templateUuid;
      state.freeLookActive = payload.freeLookActive;
      // Set free look to be initialized after the first time it is active
      if (payload.freeLookActive && !state.freeLookInitialized) {
        state.freeLookInitialized = true;
      }
      state.supportedFreeLookMode = payload.supportedFreeLookMode;
    },
  },
  extraReducers: builder => {
    builder.addCase(teleopActions.updateTeleopSessionId, () => {
      // Clear state when we get a new teleop sesion Id
      return initialState;
    });
    builder.addCase(pilotActions.handleSkillStatus, (state, { payload }) => {
      // Keep track of when the active skill changes, clear mission state when it does
      // Otherwise the mission UI will stick around when we switch to non-mission skills
      if (payload.skillKey !== state.currentSkillKey) {
        return {
          ...initialState,
          currentSkillKey: payload.skillKey,
        };
      }
    });
  },
});

export default reducer;
export { missionActions };
