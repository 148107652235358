import { createSlice } from "@reduxjs/toolkit";

import { teleopActions } from "state/teleop/slice";

import type { PayloadAction } from "@reduxjs/toolkit";

export interface LiveVideoState {
  currentVideoTrack: string;
  videoLatencyEstimateMs?: number;
  lastTenVideoLatencyEstimateMs?: number[];
}

const initialState: LiveVideoState = {
  currentVideoTrack: "",
};

const { actions, reducer } = createSlice({
  name: "liveVideo",
  initialState,
  reducers: {
    updateCurrentVideoTrack(state, { payload }: PayloadAction<string>) {
      state.currentVideoTrack = payload;
    },
    updateVideoLatencyEstimateMs(state, { payload }: PayloadAction<number>) {
      state.videoLatencyEstimateMs = payload;
    },
    updateLastTenVideoLatencyEstimateMs(state, { payload }: PayloadAction<number>) {
      if (!state.lastTenVideoLatencyEstimateMs) {
        state.lastTenVideoLatencyEstimateMs = [];
      }
      state.lastTenVideoLatencyEstimateMs?.push(payload);
      if (state.lastTenVideoLatencyEstimateMs.length > 10) {
        state.lastTenVideoLatencyEstimateMs.shift();
      }
    },
  },
  extraReducers: builder =>
    builder.addCase(teleopActions.updateTeleopSessionId, () => {
      // Clear state when we get a new teleop sesion Id
      return initialState;
    }),
});

export { actions as liveVideoActions };

export default reducer;
