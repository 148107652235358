import { createSlice } from "@reduxjs/toolkit";

import { UiControlScheme } from "@skydio/pbtypes/pbtypes/gen/cloud_config/ui_control_scheme_pb";

import { teleopActions } from "state/teleop/slice";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { UiLayout } from "@skydio/channels/src/ui_layout_pb";
import type { SkillLayoutState } from "./types";

const initialState: SkillLayoutState = {
  primaryButtons: [],
  promptButtons: [],
  controlScheme: UiControlScheme.Enum.UNKNOWN,
};

const { reducer, actions: skillLayoutActions } = createSlice({
  name: "skillLayout",
  initialState,
  reducers: {
    handleUILayout(state, { payload }: PayloadAction<UiLayout.AsObject>) {
      state.azimuthButtonsEnabled = payload.azimuthButtonsEnabled;
      state.heightSliderEnabled = payload.heightSliderEnabled;
      state.zoomSliderEnabled = payload.zoomSliderEnabled;
      state.primaryButtons = payload.primaryButtonsList;
      state.promptButtons = payload.promptButtonsList;
      state.slidersList = payload.slidersList;
      state.togglesList = payload.togglesList;
      state.radiosList = payload.radiosList;
      state.controlScheme = payload.controlScheme;
      state.unifiedFollowState = payload.unifiedFollowState;
      state.safeButton = {
        safeButtonBehavior: payload.safeButtonBehavior,
        stoppableActionTitle: payload.stoppableActionTitle,
        stoppableActionText: payload.stoppableActionText,
        stoppableActionProgressRatio: payload.stoppableActionProgressRatio,
        customStopIconId: payload.customStopIconId,
      };
      state.title = payload.title;
      state.detail = payload.detail;
      state.showSettingsSliders = !!payload.primarySlider || !!payload.secondarySlider;
      state.tapTargetsEnabled = payload.tapTargetsEnabled;
    },
  },
  extraReducers: builder =>
    builder.addCase(teleopActions.updateTeleopSessionId, () => {
      // Clear state when we get a new teleop sesion Id
      return initialState;
    }),
});

export { skillLayoutActions };
export default reducer;
