import { createSlice } from "@reduxjs/toolkit";

import { teleopActions } from "state/teleop/slice";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { SceneState } from "@skydio/channels/src/scene_state_pb";
import type { ArModel } from "@skydio/pbtypes/pbtypes/gen/ar_video_shaders/ar_model_pb";
import type { ArMarker } from "@skydio/pbtypes/pbtypes/gen/skills/ar_marker_pb";
import type { Goal } from "@skydio/pbtypes/pbtypes/gen/skills/goal_pb";
import type { Polyline } from "@skydio/pbtypes/pbtypes/gen/skills/polyline_pb";
import type { Prism } from "@skydio/pbtypes/pbtypes/gen/skills/prism_pb";
import type { MinimalMotionState } from "@skydio/pbtypes/pbtypes/gen/subject/minimal_motion_state_pb";
import type { MinimalTrackerState } from "@skydio/pbtypes/pbtypes/gen/subject/minimal_tracker_state_pb";

export type Landmark = { cartesianPos: [number, number, number]; type?: string; id: string };

export interface ARState {
  prisms: Prism.AsObject[];
  polylines: Polyline.AsObject[];
  markers: ArMarker.AsObject[];
  goals: Goal.AsObject[];
  models: ArModel.AsObject[];
  trackerState?: MinimalTrackerState.AsObject;
  showArCoverageMesh: boolean;
  landmarks?: Landmark[];
}

const initialState: ARState = {
  prisms: [],
  polylines: [],
  markers: [],
  goals: [],
  models: [],
  showArCoverageMesh: false,
};

const { reducer, actions: arActions } = createSlice({
  name: "ar",
  initialState,
  reducers: {
    handleSceneState(state, { payload }: PayloadAction<SceneState.AsObject>) {
      state.showArCoverageMesh = payload.showArCoverageMesh;
      state.prisms = payload.patchesList;
      state.polylines = payload.polylinesList;
      state.markers = payload.markersList;
      state.goals = payload.goalsList;
      state.models = payload.modelsList;
    },
    deleteGoalPost(state, { payload }: PayloadAction<Goal.AsObject>) {
      state.goals.filter(goal => goal.sceneTCenter?.utime !== payload.sceneTCenter?.utime);
    },
    handleSubjectTrackState(
      state,
      { payload: { trackerState } }: PayloadAction<MinimalMotionState.AsObject>
    ) {
      state.trackerState = trackerState;
    },
    setLandmarks(state, { payload }: PayloadAction<Landmark[]>) {
      state.landmarks = payload;
    },
    clearArState(state) {
      state.prisms = [];
      state.polylines = [];
      state.markers = [];
      state.goals = [];
      state.models = [];
    },
  },
  extraReducers: builder =>
    builder.addCase(teleopActions.updateTeleopSessionId, () => {
      // Clear state when we get a new teleop sesion Id
      return initialState;
    }),
});

export default reducer;
export { arActions };
