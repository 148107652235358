import { isEqual, pick } from "lodash";

import type { VehicleSettings } from "@skydio/pbtypes/pbtypes/vehicle/infrastructure/flight_deck/settings_pb";

/**
 * These are the current list of vehicle settings that can be edited by the user in teleop.
 * When adding a new editable vehicle setting in teleop, make sure to add it to this list.
 */
export const currentlyEditableSettings = [
  "illuminationMode",
  "navigationMode",
  "obstacleMarginMode",
  "returnSettings",
  "stopAtStructure",
  "returnSettings",
  "returnWithVisionOnly",
  "heightCeilingOff",
  "heightCeilingValue",
  "ignoreGpsHeightCeil",
  "rgbLedsDisabled",
  "variableObstacleAvoidance",
  "safetyHeight",
  "navigationMode",
];

export const compareCurrentlyEditableSettings = (
  vehicleSettingsProto1: VehicleSettings,
  vehicleSettingsProto2: VehicleSettings
) => {
  const settings1 = pick(vehicleSettingsProto1.toObject(), currentlyEditableSettings);
  const settings2 = pick(vehicleSettingsProto2.toObject(), currentlyEditableSettings);

  // compare speedNearObstacles separately because we need to round the number due to javascript precision
  const speedNearObstacles1 = Math.round(vehicleSettingsProto1.getSpeedNearObstacles() * 100) / 100;
  const speedNearObstacles2 = Math.round(vehicleSettingsProto2.getSpeedNearObstacles() * 100) / 100;
  return isEqual(settings1, settings2) && speedNearObstacles1 === speedNearObstacles2;
};
