import type { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import type { MapSceneState } from "@skydio/channels/src/map_scene_state_pb";
import type { LngLatAltTuple } from "@skydio/math";
import type { FlightPlayerState } from "../slice";

export const handleMapCaptureMessage: CaseReducer<FlightPlayerState, any> = (
  state,
  { payload: mapSceneStateMessage }: PayloadAction<MapSceneState.AsObject>
) => {
  const polylines = mapSceneStateMessage.objectsList.filter(x => x.polyline !== undefined);
  if (polylines.length >= 1 && polylines[0].polyline) {
    const trajectory = polylines[0].polyline.waypointsList
      .filter(x => x.waypoint?.xy?.y != undefined && x.waypoint.xy.x != undefined)
      .map(x => [x.waypoint?.xy?.y, x.waypoint?.xy?.x] as LngLatAltTuple);
    state.mapCaptureTrajectory = trajectory;
  }
  if (polylines.length >= 2 && polylines[1].polyline) {
    const trajectory = polylines[1].polyline.waypointsList
      .filter(x => x.waypoint?.xy?.y != undefined && x.waypoint.xy.x != undefined)
      .map(x => [x.waypoint?.xy?.y, x.waypoint?.xy?.x] as LngLatAltTuple);
    state.mapCaptureCompletedTrajectory = trajectory;
  }
};
