import { createSlice } from "@reduxjs/toolkit";

import { UPDATE_VEHICLE_SETTINGS_PB } from "@skydio/channels/src/update_vehicle_settings_pb";
import { VehicleSettings } from "@skydio/pbtypes/pbtypes/vehicle/infrastructure/flight_deck/settings_pb";

import { teleopActions } from "state/teleop/slice";

import { compareCurrentlyEditableSettings } from "./utils";

import type { PayloadAction } from "@reduxjs/toolkit";
import type UnifiedSkybus from "@skydio/skybus/src/unified";
import type { SettingsModalTabsKey } from "components/main/teleop/overlay/SettingsModalV3/SettingsModalV3";

export type VehicleSettingsRequestState =
  | {
      status: "idle";
    }
  | {
      status: "ready";
    }
  | {
      status: "inflight";
    }
  | {
      status: "success";
    }
  | {
      status: "error";
      error: string;
    };
export type SkybusUpdateVehicleSettingsAction = {
  skybus: UnifiedSkybus;
  vehicleSettings: VehicleSettings;
};

export interface VehicleSettingsState {
  desiredVehicleSettings?: Partial<VehicleSettings.AsObject>;
  vehicleSettingsProto: VehicleSettings;
  desiredVehicleSettingsProto: VehicleSettings;
  vehicleSettingsRequestState: VehicleSettingsRequestState;
  isTeleopSettingsModalOpen: boolean;
  isOASettingsModalOpen: boolean;
  defaultSettingsModalActiveKey: SettingsModalTabsKey;
}

export interface DesiredVehicleSettingsProtoAction {
  desiredVehicleSettingsProto: VehicleSettings;
  sendRequest: boolean;
}

const initialState: VehicleSettingsState = {
  desiredVehicleSettings: {},
  vehicleSettingsProto: new VehicleSettings(),
  desiredVehicleSettingsProto: new VehicleSettings(),
  vehicleSettingsRequestState: { status: "idle" },
  isTeleopSettingsModalOpen: false,
  isOASettingsModalOpen: false,
  defaultSettingsModalActiveKey: "flightControls",
};

const { reducer, actions: vehicleSettingsActions } = createSlice({
  name: "vehicleSettings",
  initialState,
  reducers: {
    setDesiredVehicleSettings(
      state,
      { payload }: PayloadAction<Partial<VehicleSettings.AsObject>>
    ) {
      if (!state.desiredVehicleSettings) {
        state.desiredVehicleSettings = {};
      }
      Object.assign(state.desiredVehicleSettings, payload);
    },
    resetDesiredVehicleSettings(state) {
      state.desiredVehicleSettings = {};
    },
    handleVehicleSettingsProto(state, { payload }: PayloadAction<VehicleSettings>) {
      state.vehicleSettingsProto = payload;
      // initialize the desired settings if they haven't been set yet (utime is empty)
      // or if neither the vehicle settings modal nor is the oa settings modal is open, reset the desired settings to the current settings.
      // If we get a new validation id from the vehicle, reset the desired settings to the current settings.
      // This ensures that the validation id is always up to date, and the vehicle won't reject settings because of a mismatched validation id.
      if (
        !state.desiredVehicleSettingsProto.getUtime() ||
        (!state.isTeleopSettingsModalOpen && !state.isOASettingsModalOpen) ||
        state.desiredVehicleSettingsProto.getValidationId() !== payload.getValidationId()
      ) {
        state.desiredVehicleSettingsProto = payload;
      }
      // if the vehicle settings request is inflight, check if the desired settings match the current settings
      // if they do, mark the request as successful
      if (state.vehicleSettingsRequestState.status === "inflight") {
        if (compareCurrentlyEditableSettings(state.desiredVehicleSettingsProto, payload)) {
          state.vehicleSettingsRequestState = { status: "success" };
        }
      }
      // if we are in remote flight deck and the current vehicle settings have nightsenseOffAllowedWithPoorGPS set to true,
      // set nightsenseOffAllowedWithPoorGPS to false
      if (payload.getNightsenseOffAllowedWithPoorGps()) {
        state.desiredVehicleSettingsProto.setNightsenseOffAllowedWithPoorGps(false);
        state.vehicleSettingsRequestState = { status: "ready" };
      }
    },
    setDesiredVehicleSettingsProto(
      state,
      { payload }: PayloadAction<DesiredVehicleSettingsProtoAction>
    ) {
      state.desiredVehicleSettingsProto = payload.desiredVehicleSettingsProto;
      // set vehicle request state to "ready" when the desired settings are updated, marking that they are ready to be sent
      // this is handled in the useUpdateAllSettings hook
      if (payload.sendRequest) {
        state.vehicleSettingsRequestState = { status: "ready" };
      }
    },
    resetDesiredVehicleSettingsProto(state) {
      // reset to current vehicle settings if they exist
      state.desiredVehicleSettingsProto = state.vehicleSettingsProto || new VehicleSettings();
    },
    sendVehicleSettingsChangeRequest(
      state,
      { payload }: PayloadAction<SkybusUpdateVehicleSettingsAction>
    ) {
      const { skybus, vehicleSettings } = payload;

      state.vehicleSettingsRequestState = { status: "inflight" };
      skybus.publish(UPDATE_VEHICLE_SETTINGS_PB, vehicleSettings);
    },
    setVehicleSettingsRequestState(state, { payload }: PayloadAction<VehicleSettingsRequestState>) {
      state.vehicleSettingsRequestState = payload;
    },
    resetVehicleSettingsRequestState(state) {
      state.vehicleSettingsRequestState = { status: "idle" };
    },
    setIsTeleopSettingsModalOpen(state, { payload }: PayloadAction<boolean>) {
      state.isTeleopSettingsModalOpen = payload;
    },
    setIsOASettingsModalOpen(state, { payload }: PayloadAction<boolean>) {
      state.isTeleopSettingsModalOpen = payload;
    },
    setSettingsModalDefaultActiveKey(state, { payload }: PayloadAction<SettingsModalTabsKey>) {
      state.defaultSettingsModalActiveKey = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(teleopActions.updateTeleopSessionId, () => {
      // Clear state when we get a new teleop sesion ID
      return { ...initialState };
    });
  },
});

export default reducer;
export { vehicleSettingsActions };
