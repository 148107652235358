import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";

export type DownloadFilesTabKey = "in_progress" | "recent";

export const DEFAULT_DOWNLOAD_FILES_JOB_NAME = "zip-files";
export const DEFAULT_DOWNLOAD_FILES_AUTO_DOWNLOAD = true;

export const DOWNLOAD_FILES_IN_PROGRESS_TAB_KEY: DownloadFilesTabKey = "in_progress";
export const DOWNLOAD_FILES_RECENT_FILES_TAB_KEY: DownloadFilesTabKey = "recent";

export interface DownloadFilesState {
  isDownloadingFilesVisible: boolean;
  isDownloadingFilesMinimized: boolean;
  autoDownloadOnSuccess: boolean;
  jobName: string;
  searchParams: Record<string, any> | null;
  activeTabKey: "in_progress" | "recent";
  focusedItemId: string | null; // if present, the item with this node ID will be briefly highlighted
}

const initialState: DownloadFilesState = {
  isDownloadingFilesVisible: false,
  isDownloadingFilesMinimized: false,
  autoDownloadOnSuccess: DEFAULT_DOWNLOAD_FILES_AUTO_DOWNLOAD,
  jobName: DEFAULT_DOWNLOAD_FILES_JOB_NAME,
  searchParams: null,
  activeTabKey: DOWNLOAD_FILES_IN_PROGRESS_TAB_KEY,
  focusedItemId: null,
};

const { reducer, actions } = createSlice({
  name: "downloadFiles",
  initialState,
  reducers: {
    setIsDownloadingFilesVisible(state, action: PayloadAction<boolean>) {
      state.isDownloadingFilesVisible = action.payload;
    },
    toggleIsDownloadingFilesVisible(state) {
      state.isDownloadingFilesVisible = !state.isDownloadingFilesVisible;
    },
    setIsDownloadingFilesMinimized(state, action: PayloadAction<boolean>) {
      state.isDownloadingFilesMinimized = action.payload;
    },
    toggleIsDownloadingFilesMinimized(state) {
      state.isDownloadingFilesMinimized = !state.isDownloadingFilesMinimized;
    },
    setJob(state, action: PayloadAction<{ jobName: string; searchParams: Record<string, any> }>) {
      state.jobName = action.payload.jobName || DEFAULT_DOWNLOAD_FILES_JOB_NAME;
      state.searchParams = action.payload.searchParams;
    },
    setAutoDownloadOnSuccess(state, action: PayloadAction<boolean>) {
      state.autoDownloadOnSuccess = action.payload;
    },
    setActiveTabKey(state, action: PayloadAction<"in_progress" | "recent">) {
      state.activeTabKey = action.payload;
    },
    setFocusedItemId(state, action: PayloadAction<string | null>) {
      state.focusedItemId = action.payload;
    },
    resetToDefault(state) {
      return initialState;
    },
  },
});

export default reducer;
export { actions as downloadFilesActions };
