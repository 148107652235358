import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";

export interface FleetState {
  [vehicleId: string]: {
    latitude: number;
    longitude: number;
  };
}

export interface FleetPayload {
  deviceId: string;
  latitude: number;
  longitude: number;
}

const initialState: FleetState = {};

const { actions, reducer } = createSlice({
  name: "fleetDeviceCoordinates",
  initialState,
  reducers: {
    updateFleetDeviceCoordinates(state, { payload }: PayloadAction<FleetPayload>) {
      state[payload.deviceId] = {
        latitude: payload.latitude,
        longitude: payload.longitude,
      };
    },
  },
});

export { actions as fleetActions };

export default reducer;
