import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { LivekitHealthStats } from "@skydio/pbtypes/pbtypes/experimental/webrtc_c_impl/webrtc_stats_pb";
import type { LivekitConnectionStatus } from "@skydio/pbtypes/pbtypes/infrastructure/remote_streaming/livekit_streaming_pb";

export interface LivekitConnectionStatusState {
  livekitConnectionStatus?: LivekitConnectionStatus.AsObject;
  livekitConnectionHealth?: LivekitHealthStats.AsObject;
  noLivekitData?: boolean;
}

const initialState: LivekitConnectionStatusState = {};

const { reducer, actions: livekitActions } = createSlice({
  name: "livekit",
  initialState,
  reducers: {
    handleLivekitConnectionStatus(
      state,
      { payload }: PayloadAction<LivekitConnectionStatus.AsObject>
    ) {
      state.livekitConnectionStatus = payload;
    },
    resetLivekitConnectionStatus(state) {
      delete state.livekitConnectionStatus;
    },
    handleLivekitConnectionHealth(state, { payload }: PayloadAction<LivekitHealthStats.AsObject>) {
      state.livekitConnectionHealth = payload;
    },
    setNoLivekitDataFlag(state, { payload }: PayloadAction<boolean | undefined>) {
      state.noLivekitData = payload;
    },
  },
});

export default reducer;
export { livekitActions };
