import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";

export interface ResponsiveLayoutState {
  sideNavOpen: boolean;
}

const initialState: ResponsiveLayoutState = {
  sideNavOpen: false,
};

const { actions, reducer } = createSlice({
  name: "responsiveLayout",
  initialState,
  reducers: {
    updateSideNavOpen(state, { payload }: PayloadAction<boolean>) {
      state.sideNavOpen = payload;
    },
  },
});

export { actions as responsiveLayoutActions };

export default reducer;
